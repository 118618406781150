import { Col, Row, Spin, Switch, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav } from 'components/layout.component';
import { LayoutTabMember } from 'components/member.component';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { $get } from 'services';
import { specialProviderName } from 'utils/common';

// 會員列表: 遊戲商資料

const PageMain: React.FC = () => {
  const { data: site } = useSite();
  const { init, permissions: $p } = useAccount();
  const { id, platformId, account, agId } = useParams();
  const [data, setData] = useState<ProviderInfo[]>([]);

  const { data: MemberProviderList, isValidating } = $get({
    url: `admin/member/sites/${platformId}/members/${id}/provider/settings`,
    allow: !!id && !!platformId
  });

  useEffect(() => {
    if (MemberProviderList) {
      setData(MemberProviderList?.Data);
    }
  }, [MemberProviderList])

  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    if (init) {
      setColumns([
        {
          dataIndex: 'ProviderName',
          title: i18n.t('gameProvider'),
          render: (val: any, { ProviderCode, CategoryCode }: any) => `${specialProviderName(val, site.SiteName, ProviderCode)}-${i18n.t(CategoryCode)}`
        },
        {
          dataIndex: 'GameAccount',
          title: i18n.t('gameProviderAccount'),
          render: (val: any) => val
        },
        {
          dataIndex: 'Status',
          title: i18n.t('operation'),
          width: 150,
          render: (val: any, record: any) => (
            <Row justify="start" align="middle" gutter={[12, 12]}>
              <Col>
                <Switch checked={val} disabled />
              </Col>
            </Row>
          )
        },
      ])
    }
  }, [init]);

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <LayoutTabMember activeKey="2" id={id} platformId={platformId} account={account} agId={agId}/>
        <Row>
          {/* 遊戲錢包資料 */}
          <Col span={24} className="mt-1">
            <Spin spinning={isValidating}>
              <Row>
                <Col span={8}>
                  <Table
                    size="small"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 0).map(item => ({ ...item, key: item.ProviderId }))}
                    columns={columns}
                  />
                </Col>
                <Col span={8}>
                  <Table
                    size="small"
                    className="border-04"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 1).map(item => ({ ...item, key: item.ProviderId }))}
                    columns={columns}
                  />
                </Col>
                <Col span={8}>
                  <Table
                    size="small"
                    pagination={false}
                    dataSource={data?.filter((_, i) => i % 3 === 2).map(item => ({ ...item, key: item.ProviderId }))}
                    columns={columns}
                  />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PageMain;