import { ConfigProvider, message } from "antd";
import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { SocketProvider } from "hooks/socket.provider";
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from "react-redux";
import {
  Slide,
  ToastContainer,
  ToastContainerProps,
  toast,
} from "react-toastify";
import "./assets/css/main.css";
import store from "./hooks/store.hook";
import useLanguage from "hooks/language.hook";

import { MainLayout } from "components/layout.component";
import { advancedModule } from 'modules/advanced.module';
import { memberModule } from 'modules/member.module';
import { reportModule } from 'modules/report.module';

import Page404 from "pages/404.page";
import PageError from "pages/error.page";
import PageLogin from "pages/login.page";
import PageModifyPassword from "pages/modify-password.page";
import PageAdvancedIndex from "pages/site-setting.page";

import en_US from 'antd/locale/en_US';
import ja_JP from 'antd/locale/ja_JP';
import pt_BR from 'antd/locale/pt_BR';
import th_TH from 'antd/locale/th_TH';
import zh_CN from 'antd/locale/zh_CN';
import zh_TW from 'antd/locale/zh_TW';
import customViVN from "locales/customViVN";
import i18n from "i18n";

import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';

const toastConfig: ToastContainerProps = {
  transition: Slide,
  position: toast.POSITION.TOP_CENTER,
  autoClose: 1400,
  limit: 1,
  closeOnClick: true,
  draggable: false,
  hideProgressBar: true,
  pauseOnHover: false,
};

message.config({
  top: 20,
  duration: 2,
  maxCount: 3,
})

// 新增大類
const router = createHashRouter([
  {
    path: "/",
    children: [
      { path: '/', Component: PageAdvancedIndex },
      { path: '/login/:errorCode?', Component: PageLogin },
      { path: '/password', Component: PageModifyPassword },
      ...memberModule,
      ...reportModule,
      ...advancedModule,
      { path: '/error', Component: PageError },
      { path: "*", Component: Page404 },
    ],
  },
]);

ReactModal.setAppElement("#root");

const App: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { getLang: lang } = useLanguage();

  const Local = (_lang: string) => {
    switch (_lang) {
      case 'en-US':
        return en_US;
      case 'zh-TW':
        return zh_TW;
      case 'zh-CN':
        return zh_CN;
      case 'ja-JP':
        return ja_JP;
      case 'th-TH':
        return th_TH;
      case 'vi-VN':
        return customViVN;
      case 'pt-BR':
        return pt_BR;
    }
  }

  useEffect(() => {
    document.title = `369 - ${i18n.t('generalControlDashboard')}`;

    // 時區
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(isoWeek);
  }, []);

  return (
    <MainLayout>
      <Provider store={store}>
        <HelmetProvider>
          <SocketProvider>
            <ConfigProvider locale={Local(lang)}>
              {contextHolder}
              <ToastContainer {...toastConfig} />
              <RouterProvider router={router} />
            </ConfigProvider>
          </SocketProvider>
        </HelmetProvider>
      </Provider>
    </MainLayout>
  );
};

export default App;
