import { CloseOutlined, DownOutlined, NotificationFilled, SettingFilled } from '@ant-design/icons';
import type { UploadFile } from 'antd';
import { Badge, Breadcrumb, Button, Card, Col, DatePicker, Divider, Drawer, Flex, FloatButton, Form, Image, Input, InputNumber, Layout, List, Menu, message, Radio, Row, Spin, Tabs, Upload } from "antd";
import ImgCrop from 'antd-img-crop';
import { Excel } from 'antd-table-saveas-excel';
import { RangePickerProps } from 'antd/es/date-picker';
import { RcFile, UploadChangeParam } from "antd/es/upload";
import Pagination, { PaginationProps } from 'antd/lib/pagination';
import logo01Icon from 'assets/image/logo.ico';
import logo from 'assets/image/logo.svg';
import logoutIcon from 'assets/image/logout.svg';
import mailboxIcon from 'assets/image/mailbox.svg';
import { COOKIE } from 'constants/cookie';
import { DATE_RANGE_LIMIT, DATE_TYPE } from 'constants/date';
import { RESPONSE_CODE } from 'constants/response';
import dayjs, { Dayjs } from 'dayjs';
import useAccount from 'hooks/account.hook';
import useLanguage, { LANG } from 'hooks/language.hook';
import i18n from 'i18n';
import { DefaultTFuncReturn } from 'i18next';
import Cookies from 'js-cookie';
import QueryString from 'qs';
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { $get } from 'services';
import { determineImageAttributes, enumToOptions, replaceAllMultiple } from 'utils/common';
import $package from "../../package.json";
import copyIcon from 'assets/image/icon-10.svg';
import { responseProcessing } from 'services';
import { advancedModule } from 'modules/advanced.module';
import { memberModule } from 'modules/member.module';
import { reportModule } from 'modules/report.module';

const topBarHeight = '55px';

// 排版: 主要
export const MainLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Layout style={{ background: "#fff" }}>
      {children}
      <FloatButton.BackTop visibilityHeight={1} />
    </Layout>
  )
};

// 排版: 登入畫面
export const ModalLayout: React.FC<{ children: ReactNode, paddingBottom?: string }> = ({ children, paddingBottom = 0 }) => {
  return (
    <>
      {/* <Helmet>
        <link rel="icon" href={$s?.Logo1} type="image/x-icon" />
      </Helmet > */}
      <Flex
        className="login-background-image" justify="center" align="center"
        style={{ paddingBottom }}
      >
        <Card className="login-card">
          <Row className='login-box' justify="center" align="middle">
            <Col>
              <Image src={logo01Icon} preview={false} />
            </Col>
          </Row>
          {children}
        </Card>
      </Flex>
    </>
  )
};

// 上方黑條
export const LayoutNav: React.FC<{
  hidden?: boolean;
}> = ({ hidden }) => {
  
  const navigate = useNavigate();
  const $n = (url: any) => navigate(url);
  const { info, logout, permissions: $p } = useAccount();
  const { list: languageList, getLang: lang, setLang } = useLanguage();
  const { i18n } = useTranslation();
  const [currentTime, setCurrentTime] = useState<string>();

  useEffect(() => {
    refreshSystemTime();

    const interval = setInterval(() => {
      refreshSystemTime();
    }, 1000)
    return () => clearInterval(interval);
  }, []);

  const refreshSystemTime = () => {
    setCurrentTime(dayjs.tz().format('YYYY-MM-DD HH:mm:ss'));
  }

  const setTimeZone = (tz: string) => {
    tz
    ? dayjs.tz.setDefault(tz)
    : dayjs.tz.setDefault()

    refreshSystemTime();
  }

  // 公告
  const [isOpenAnnouncement, setIsOpenAnnouncement] = useState(false);
  const [page, setPage] = useState<number[]>([1, 10]);
  const { data: SystemAnnouncement, isValidating, mutate } = $get({
    url: 'admin/system-announcement/publish/announcements',
    params: {
      page: page[0],
      rows: page[1]
    },
  })

  // 頁面選單
  const pageOptions: any = [
    ($p('member.getMemberList') && {
      key: 'member',
      label:
        <div className="item-style" >
          <span>{i18n.t('member')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('member.getMemberList') &&
        { key: 'member-1', label: i18n.t('memberList'), onClick: () => $n('/member') },
      ],
    }),
    (($p('betRecord.getBetRecord') || $p('providerReceivable.getReport') || $p('smsLog.getLogs')) && {
      key: 'report',
      label:
        <div className="item-style">
          <span>{i18n.t('statisticalReports')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
        $p('betRecord.getBetRecord') &&
        { key: 'report-1', label: i18n.t('bettingReports'), onClick: () => $n('/report') },
        // $p('nooo.open') &&
        // { key: 'report-2', label: i18n.t('dataOverview'), onClick: () => $n('/') },
        // $p('nooo.open') &&
        // { key: 'report-3', label: i18n.t('operationReports'), onClick: () => $n('/report/business') },
        $p('providerReceivable.getReport') &&
        { key: 'report-4', label: i18n.t('settlementReport'), onClick: () => $n('/report/settlement') },
        $p('smsLog.getLogs') &&
        { key: 'report-5', label: i18n.t('smsRecord'), onClick: () => $n('/report/sms') },
      ],
    }),
    ((
     ($p('siteData.getSiteData') || $p('gameData.getProviderListByCC') || $p('providerSort.getProviderSortList') || $p('gameList.getGameList') || $p('providerCommission.getProviderCommissions') || $p('license.getLicenses')) || 
      $p('permissionRole.getRoles') || 
      $p('systemAnnouncement.getSystemAnnouncements') ||
      $p('game.getCCGames') ||
      $p('adminUserLoginLog.getAdminUserLoginLogs')
    ) && {
      key: 'advanced',
      label:
        <div className="item-style">
          <span>{i18n.t('advanced')}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: [
       ($p('siteData.getSiteData') || $p('gameData.getProviderListByCC') || $p('providerSort.getProviderSortList') || $p('gameList.getGameList') || $p('providerCommission.getProviderCommissions') || $p('license.getLicenses')) &&
        { key: 'advanced-1', label: i18n.t('platformSettings'), onClick: () => {
          if ($p('siteData.getSiteData')) $n('/')
          else if ($p('gameData.getProviderListByCC')) $n('/advanced/platform-game-data')
          else if ($p('providerSort.getProviderSortList')) $n('/advanced/platform-provider-sort')
          else if ($p('gameList.getGameList')) $n('/advanced/platform-game-list')
          else if ($p('providerCommission.getProviderCommissions')) $n('/advanced/platform-game-rebate')
          else if ($p('license.getLicenses')) $n('/advanced/platform-license')
        } },
        $p('permissionRole.getRoles') &&
        { key: 'advanced-2', label: i18n.t('permissionManagement'), onClick: () => $n('/advanced/permission') },
        $p('systemAnnouncement.getSystemAnnouncements') &&
        { key: 'advanced-3', label: i18n.t('systemAnnouncement'), onClick: () => $n('/advanced/announcement') },
        $p('game.getCCGames') &&
        { key: 'advanced-4', label: i18n.t('gameManagement'), onClick: () => $n('/advanced/game-management') },
        $p('adminUserLoginLog.getAdminUserLoginLogs') &&
        { key: 'advanced-5', label: i18n.t('loginRecords'), onClick: () => $n('/advanced/login-record') },
      ],
    })
  ];

  // 功能選單
  const toolOptions: any = [
    {
      key: 'utc',
      label: 
        <div className='topbar-item time-zone'>
          <div>
            <div>{`UTC${dayjs().tz().format('Z')}`}</div>
            <div>{currentTime}</div>
          </div>
          <div style={{ marginLeft: 10 }}>
            <DownOutlined />
          </div>
        </div>,
      children: [
        { key: "", label: i18n.t('systemTime') },
        { key: "Etc/UCT", label: "UTC +0" },
        { key: "Asia/Taipei", label: "UTC +8" },
      ].map((item: any) => ({
        key: item.key,
        label: item.label,
        onClick: () => setTimeZone(item.key)
      }))
    },
    {
      key: 'language',
      label:
        <div className="topbar-item">
          <span>{LANG[lang as keyof typeof LANG]}</span>
          <span style={{ marginLeft: 2 }}><DownOutlined /></span>
        </div>,
      children: languageList.map((item: any) => ({
        key: item,
        label: LANG[item as keyof typeof LANG],
        onClick: () => setLang(item)
      }))
    },
    {
      key: 'mailbox',
      label: 
        <Badge offset={[1, 1]}>
          <Image src={mailboxIcon} width={25} height={25} preview={false} />
        </Badge>,
      onClick: () => {
        mutate();
        setIsOpenAnnouncement(true);
      }
    },
    {
      key: 'admin',
      label:
        <Row align="middle">
          <Col>{info.account}</Col>
        </Row>,
      children: [
        {
          key: 'password',
          label: i18n.t('modifyPassword'),
          onClick: () => navigate('/password')
        },
        {
          key: 'logout',
          label:
            <Row align="middle" gutter={8}>
              <Col><Image src={logoutIcon} preview={false} /></Col>
              <Col>{i18n.t('logout')}</Col>
            </Row>,
          onClick: logout
        },
        {
          key: 'ver',
          label:
            <Row className="version-box" justify="center">
              <Col>{`${i18n.t('version')}(${$package.version})`}</Col>
            </Row>
        },
      ],
    }
  ]

  return (
    <>
      {/* 主工具列 */}
      {/* <Helmet>
        <link rel="icon" href={$s?.Logo1} type="image/x-icon" />
      </Helmet > */}
      <Row
        align="middle"
        justify="space-between"
        className="bg-color-02"
        style={{
          height: topBarHeight
        }}
      >
        <Col span={16}>
          <Menu
            className=""
            mode="horizontal"
            theme="dark"
            items={[
              {
                key: '/',
                label:
                  <Image
                    className="nav-logo"
                    src={logo} height={30} preview={false}
                    onClick={() => $n('/')}
                  />
              },
              ...pageOptions
            ]}
          />
        </Col>
        <Col span={8}>
          <Menu
            className="pr-1 justify-content-end"
            mode="horizontal"
            theme="dark"
            items={toolOptions}
          />
        </Col>
      </Row>

      {!hidden && <BreadcrumbBar />}

      <Announcement open={isOpenAnnouncement} loading={isValidating}
        close={() => setIsOpenAnnouncement(false)} list={SystemAnnouncement} page={page} setPage={setPage} />
    </>
  );
};

// 麵包屑
export const BreadcrumbBar: React.FC<any> = () => {

  const location = useLocation();
  const [breadcrumbItems, setBreadcrumbItems] = useState();
  const { id, account, platformId, agId } = useParams<any>();
  const replaceSymbol = {
    ":id": id,
    ":account": account,
    ":platformId": platformId,
    ":agId": agId
  }

  // memberModule被換過一次後就會undefined，不能深拷貝就要搬回來
  // 其實加欄位註明就好
  useEffect(() => {
    const mapObject = new Map();

    memberModule[0].children.forEach(e => replaceParams(e, mapObject));
    advancedModule[0].children.forEach(e => replaceParams(e, mapObject));
    reportModule[0].children.forEach(e => replaceParams(e, mapObject));

    // 站台資訊先當首頁
    mapObject.set('/', [
      { title: i18n.t('advanced') },
      { title: i18n.t('platformSettings') },
      { title: i18n.t('platformData') },
    ]);
    // 選填 params 無法自動化
    mapObject.set('/advanced/account', [
      { title: i18n.t('advanced') },
      { title: i18n.t('permissionManagement') }
    ]);
    mapObject.set(`/advanced/account/${id}`, [
      { title: i18n.t('advanced') },
      { title: i18n.t('permissionManagement') }
    ]);
   
    setBreadcrumbItems(mapObject.get(location.pathname));
  }, [location.pathname]);

  const replaceParams = (page: any, mapObject: any) => {
    if (page.Breadcrumb) {
      const url = replaceAllMultiple(page.path, replaceSymbol);

      const breadcrumb = page.Breadcrumb.map((layer: any) => {
        if (typeof layer.title === 'string') {
          layer.title = replaceAllMultiple(layer.title, replaceSymbol);
        }
        return layer;
      });

      mapObject.set(url, breadcrumb);
    }
  }

  return (
    <>
      <Breadcrumb
        style={{ padding: '17px 33px' }}
        separator=">"
        items={breadcrumbItems}
      />
      <Divider style={{ margin: '0px 0px 20px 0px' }} />
    </>
  )
}

// 系統公告 (右側欄)
export const Announcement: React.FC<{
  open: boolean;
  close: () => void;
  list: any;
  loading: any;
  page: number[];
  setPage: any;
}> = ({ open, close, list, loading, page, setPage }) => {

  const [loginDisplay, setLoginDisplay] = useState(false);
  const [details, setDetails] = useState<any>();
  const [childrenDrawer, setChildrenDrawer] = useState(false);

  useEffect(() => {
    if (!Cookies.get(COOKIE.FIRST_LOGIN)) {
      setLoginDisplay(true)
    }
  }, []);

  const showChildrenDrawer = (key: number) => {
    setChildrenDrawer(true);
    setDetails(list?.Data.find((item: any) => item.id === key));
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  const onClose = () => {
    if (!Cookies.get(COOKIE.FIRST_LOGIN)) {
      Cookies.set(COOKIE.FIRST_LOGIN, 'false', { expires: 1 });
      setLoginDisplay(false);
    }
    close();
  }

  return (
    <Drawer
      width={'450px'}
      styles={{
        header: {
          height: topBarHeight,
          background: '#1E211D'
        }
      }}
      onClose={onClose}
      closable={false}
      title={
        <Row align="middle" justify="space-between">
          <Col>
            <Row align="middle" gutter={10}>
              <Col><Image src={mailboxIcon} preview={false} height={22} /></Col>
              <Col className="size-16 color-02">{i18n.t('systemAnnouncement')}</Col>
            </Row>
          </Col>
          <Col style={{ cursor: 'pointer' }} onClick={onClose}>
            <CloseOutlined className="size-16 color-02" />
          </Col>
        </Row >
      }
      open={
        loginDisplay || open
      }
    >
      <Spin spinning={loading}>
        <List
          style={{ marginBottom: 50 }}
          itemLayout="horizontal"
          dataSource={list?.Data}
          renderItem={(item: any, index) => (
            <List.Item key={item.id} onClick={() => showChildrenDrawer(item.id)}>
              <List.Item.Meta
                title={
                  <div className="size-18 text-ellipsis">
                    {<SettingFilled />} {item.title}
                  </div>
                }
                description={
                  <div className="size-14 text-ellipsis">
                    {item.contents.replace(/<\/?[^>]+(>|$)/g, "")}
                  </div>
                }
              />
            </List.Item>
          )}
        />
        {/* 頁碼 */}
        <div style={{ position: 'fixed', bottom: 30, right: 30 }}>
          <LayoutPagination total={list?.Total} setPage={setPage} page={page} hiddenSizeChange={false} />
        </div>

        {/* 內容 */}
        {
          details &&
          <Drawer
            width={'450px'}
            styles={{
              header: {
                height: topBarHeight,
                background: '#1E211D'
              }
            }}
            onClose={onChildrenDrawerClose}
            closable={false}
            title={
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle" gutter={10}>
                    <Col><NotificationFilled className="size-16 color-01" /></Col>
                    <Col className="size-16 color-02">{i18n.t('announcementDetail')}</Col>
                  </Row>
                </Col>
                <Col style={{ cursor: 'pointer' }} onClick={onChildrenDrawerClose}>
                  <CloseOutlined className="size-16 color-02" />
                </Col>
              </Row >
            }
            open={childrenDrawer}
          >
            <Row style={{ padding: 20 }} >
              <Col span={24} className="font-w-md color-pass size-18">
                {i18n.t('mainTitle')}：
              </Col>
              <Col span={24} className="size-16" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                {details.title}
              </Col>

              <Col span={24} className="size-18 font-w-md color-down pt-1">
                {i18n.t('content')}：
              </Col>
              <Col span={24} className="size-16" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}
                dangerouslySetInnerHTML={{ __html: details.contents }} />
            </Row>
          </Drawer>
        }
      </Spin >
    </Drawer >
  )
}

// 頁碼
export const LayoutPagination: React.FC<any> = ({
  defaultPageSize = 10,
  defaultPageIndex = 1,
  page,
  setPage,
  total,
  hiddenSizeChange = true
}: {
  defaultPageSize?: number;
  defaultPageIndex?: number;
  page?: number[];
  setPage: React.Dispatch<React.SetStateAction<number[]>>;
  total: number;
  hiddenSizeChange?: boolean;
}) => {
  const [current, setCurrent] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);

  // 換頁時滑動到頂部
  useEffect(() => {
    if (page && page[0]) {
      window.scrollTo(0, 0);
    }
  }, [page]);

  const onShowSizeChange: PaginationProps['onShowSizeChange']
    = (current, pageSize) => {
      setPage([current, pageSize]);
      setCurrent(current);
      setCurrentPageSize(pageSize);
    };

  return (
    <Row justify="end" className="mt-1">
      <Pagination
        showSizeChanger={hiddenSizeChange}
        total={total}
        onChange={onShowSizeChange}
        current={page ? page[0] : current}
        pageSize={page ? page[1] : currentPageSize}
        defaultPageSize={defaultPageSize}
        showTotal={(total) => `${i18n.t('overall')} ${total} ${i18n.t('item')}`}
      />
    </Row>
  )
}

/* 匯出功能 */
export const Export = ({ url, fileName, sheetName, columns, param, otherData, reverse, externalData, showLink = false, className = 'excel-btn', title = "exportExcel", disabled = false }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const exportExcel = async () => {
    const query = QueryString.stringify(param, {
      addQueryPrefix: true,
    });
    setIsLoading(true);
    try {
      await fetch(`/${url}${query}`, {
        headers: {
          "Authorization": Cookies.get(COOKIE.TOKEN) as any
        },
        method: 'GET',
      }).then(res => {
        return res.json();
      })
        .then(resData => {

          // api資料
          if (!externalData) {
            const data = resData;
            if (data.length === 0) return message.success(i18n.t('listIsNoData'));
            if (data.length !== 0) {
              const excel = new Excel();
              excel
                .addSheet(sheetName)
                .addColumns(columns)
                .addDataSource(
                  reverse
                    ? data.map((item: any) => ({ ...item, ...otherData })).reverse()
                    : data.map((item: any) => ({ ...item, ...otherData }))
                  , { str2Percent: true }
                )
                .saveAs(`${fileName || dayjs().format('YYYYMMDDHHmm')}.xlsx`, "blob");
              message.success(i18n.t('downloadSuccess'));
            }
            // 外部資料
          } else {
            const excel = new Excel();
            excel
              .addSheet(sheetName)
              .addColumns(columns)
              .addDataSource(
                externalData.map((item: any) => ({ ...item, ...otherData }))
                , { str2Percent: true }
              )
              .saveAs(`${dayjs().format('YYYYMMDDHHmm')}.xlsx`, "blob");
            message.success(i18n.t('downloadSuccess'));
          }
        })

      setIsLoading(false);
    } catch (error) {
      message.success(i18n.t('listIsNoData'));
      setIsLoading(false);
    }
  }

  return (
    <Button loading={isLoading} className={!disabled ? className : ''} type={showLink && 'link'}
      disabled={disabled} onClick={exportExcel}>{i18n.t(title)}</Button>
  )
}

// 時間選擇器(控端: unix13)
export const DatePickerUnix: React.FC<{
  form?: any, name?: any,
  width?: number,         // 寬度
  date: any,              // state
  setDate: any,           // state
  initDate?: boolean,     // 重置
  format?: string,        // 格式
  ignoreDateType?: any,   // 不顯示週期
  displayCom?: any,       // 顯示元件
  disabled?: boolean,     // 全禁用
  rangeLimit?: any,       // 搜尋範圍
  defaultDateType?: any,  // 預設
  setFormChanged?: any    // 表單更動
  textAlign?: string,     // 對齊
  components?: any,       // 中間元件
}> = ({
  form, name,
  width,
  date,
  setDate,
  initDate,
  format = 'YYYY-MM-DD HH:mm:ss',
  ignoreDateType,
  displayCom = ['Picker', 'Radio'],
  disabled,
  rangeLimit = DATE_RANGE_LIMIT.all,
  defaultDateType,
  setFormChanged = () => { },
  textAlign,
}) => {
  const today = dayjs().startOf('day');
  const yesterday = today.subtract(1, 'day');
  // 需求: 週一開始 週日算上禮拜
  const thisWeek = today.startOf('isoWeek');
  const lastWeek = thisWeek.subtract(1, 'week');
  const thisMonth = today.startOf('month');
  const lastMonth = thisMonth.subtract(1, 'month');

  const showTime = format.includes('HH');

  const [pickerStartOpen, setPickerStartOpen] = useState(false);
  const [pickerEndOpen, setPickerEndOpen] = useState(false);

  // 初始化
  useEffect(() => {
    dateSwitch(defaultDateType || DATE_TYPE.today);
  }, [initDate])

  // Picker快捷
  const presets: { id: DATE_TYPE, label: string, value: Dayjs[] }[] = [
    {
      id: DATE_TYPE.today,
      label: i18n.t('today'),
      value: [today, today.endOf('day')]
    },
    {
      id: DATE_TYPE.yesterday,
      label: i18n.t('yesterday'),
      value: [yesterday, yesterday.endOf('day')]
    },
    {
      id: DATE_TYPE.thisWeek,
      label: i18n.t('thisWeek'),
      value: [thisWeek, thisWeek.endOf('isoWeek')]
    },
    {
      id: DATE_TYPE.lastWeek,
      label: i18n.t('lastWeek'),
      value: [lastWeek, lastWeek.endOf('isoWeek')]
    },
    {
      id: DATE_TYPE.thisMonth,
      label: i18n.t('thisMonth'),
      value: [thisMonth, thisMonth.endOf('month')]
    },
    {
      id: DATE_TYPE.lastMonth,
      label: i18n.t('lastMonth'),
      value: [lastMonth, lastMonth.endOf('month')]
    },
  ];

  // Radio快捷
  const dateSwitch = (type: DATE_TYPE) => {
    switch (type) {
      case DATE_TYPE.today:
        setDate([today.format(format), today.endOf('day').format(format), type])
        break;
      case DATE_TYPE.yesterday:
        setDate([yesterday.format(format), yesterday.endOf('day').format(format), type])
        break;
      case DATE_TYPE.thisWeek:
        setDate([thisWeek.format(format), thisWeek.endOf('isoWeek').format(format), type])
        break;
      case DATE_TYPE.lastWeek:
        setDate([lastWeek.format(format), lastWeek.endOf('isoWeek').format(format), type])
        break;
      case DATE_TYPE.thisMonth:
        setDate([thisMonth.format(format), thisMonth.endOf('month').format(format), type])
        break;
      case DATE_TYPE.lastMonth:
        setDate([lastMonth.format(format), lastMonth.endOf('month').format(format), type])
        break;
    }
    setFormChanged(true);
    if (form && name) {
      form.setFieldValue(name, date);
      form.validateFields([name]);
    }
  }

  const changeDate = (dateString: any, index: any) => {
    date[index] = dateString || '';

    // 自動區間
    if (index === 0 && dateString) {
      const final = dayjs(dateString).add((rangeLimit || 31) - 1, 'day').format(showTime ? 'YYYY-MM-DD 23:59:59' : format);
      if (
        ((rangeLimit === DATE_RANGE_LIMIT.past7Days || rangeLimit === DATE_RANGE_LIMIT.past31Days) && dayjs(date[1]).isAfter(final, 'day')) ||
        dayjs(date[0]).isAfter(dayjs(date[1]), 'day')
      ) {
        date[1] = final;
        setPickerEndOpen(true);
      }
    }

    setDate([dayjs(date[0]).format(format), dayjs(date[1]).format(format), null])
    setFormChanged(true);
    if (form && name) {
      form.setFieldValue(name, [dayjs(date[0]).format(format), dayjs(date[1]).format(format), null]);
      form.validateFields([name]);
    }
  }

  // 去空白
  const initialized = useRef(false)
  useEffect(() => {
    const w: any = window
    w.startTime = '';
    w.endTime = '';

    if (date) {
      w.startTime = date[0];
      w.endTime = date[1];
    }
    if (!initialized.current) {
      initialized.current = true;

      const input = document.getElementsByClassName('ant-picker-input')
      input[0]?.addEventListener('change', function (event: any) {
        if (format.includes('HH:mm:ss')) {
          const dateString = event.target.value.split(' ');
          w.startTime = `${dateString[0]} ${dateString[1]}`;
        } else {
          const dateString = event.target.value;
          w.startTime = `${dateString}`;
        }
        setDate([w.startTime, w.endTime, null]);
      })
      input[1]?.addEventListener('change', function (event: any) {
        if (format.includes('HH:mm:ss')) {
          const dateString = event.target.value.split(' ');
          w.endTime = `${dateString[0]} ${dateString[1]}`;
        } else {
          const dateString = event.target.value;
          w.endTime = `${dateString}`;
        }
        setDate([w.startTime, w.endTime, null]);
      })
    }
  }, [date]);

  const disabledDateStart: RangePickerProps['disabledDate'] = (current) => {
    switch (rangeLimit) {
      case DATE_RANGE_LIMIT.all:
        return false;
      case DATE_RANGE_LIMIT.future:
        return current < today.startOf('day');
      default:
        return current < today.add(-3, 'month').startOf('month');
    }
  };

  const disabledDateEnd: RangePickerProps['disabledDate'] = (current) => {
    switch (rangeLimit) {
      case DATE_RANGE_LIMIT.past7Days:
        // 沒有HH:mm:ss的格式會多一天不知道為啥
        return current < dayjs(date[0]) || dayjs(date[0]).add(showTime ? 7 : 6, 'day') < current;
      case DATE_RANGE_LIMIT.past31Days:
        return current < dayjs(date[0]) || dayjs(date[0]).add(showTime ? 31 : 30, 'day') < current;
      default:
        return current < dayjs(date[0]);
    }
  };

  return <>
    {/* 時間區間 */}
    {displayCom.includes('Picker') && <>
      <Col span={''}>
        <DatePicker
          open={pickerStartOpen}
          onOpenChange={setPickerStartOpen}
          showNow={false}
          style={{ width }}
          placeholder={i18n.t('startTime') as string}
          placement={'bottomLeft'}
          format={format}
          showTime={showTime ? {
            defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
          } : false}
          value={dayjs(date[0])}
          allowClear={false}
          onChange={(e, d) => changeDate(d, 0)}
          disabled={disabled}
          disabledDate={disabledDateStart}
          presets={
            displayCom.includes('Presets') && presets
              .filter(item => !ignoreDateType || !ignoreDateType.includes(item.id))
              .map(item => ({ label: item.label, value: item.value[0] })) as any
          }
        />
      </Col>
      <Col span={''}>
        <DatePicker
          open={pickerEndOpen}
          onOpenChange={setPickerEndOpen}
          showNow={false}
          style={{ width }}
          placeholder={i18n.t('endTime') as string}
          placement={'bottomLeft'}
          format={format}
          showTime={showTime ? {
            defaultValue: dayjs('23:59:59', 'HH:mm:ss'),
          } : false}
          value={dayjs(date[1])}
          allowClear={false}
          onChange={(e, d) => changeDate(d, 1)}
          disabled={disabled}
          disabledDate={disabledDateEnd}
          presets={
            displayCom.includes('Presets') && presets
              .filter(item => !ignoreDateType || !ignoreDateType.includes(item.id))
              .map(item => ({ label: item.label, value: item.value[1] })) as any
          }
        />
      </Col>
    </>}
    {/* 快捷 */}
    {displayCom.includes('Radio') &&
      <Col style={{ textAlign: textAlign as any }}>
        <Radio.Group
          style={{ width: '100%' }}
          optionType="button"
          buttonStyle="solid"
          value={date[2]}
          options={enumToOptions(DATE_TYPE).filter(item => !ignoreDateType || !ignoreDateType.includes(item.value))}
          onChange={(e) => dateSwitch(e.target.value)}
          disabled={disabled}
        />
      </Col>
    }
  </>
}

// 頁籤
export const LayoutTabPermission: React.FC<{ activeKey: string }> = ({ activeKey }) => {
  const navigate = useNavigate();
  const { permissions: $p } = useAccount();
  const [key, setKey] = useState(activeKey || '1');

  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        navigate('/advanced/permission');
        break;
      case '2':
        navigate('/advanced/account');
        break;
    }
  }

  const items: any = [
    { key: '1', label: <div className='w-8 text-center'>{i18n.t('permissionName')}</div> },
    $p('permissionUser.getUsers') &&
    { key: '2', label: <div className='w-8 text-center'>{i18n.t('backOfficeAccount')}</div> },
  ];

  return <Tabs activeKey={key} items={items} onTabClick={onTabClick} />
};

// 站台設定: 頁籤
export const LayoutTabPlatform: React.FC<{
  activeKey: string;
}> = ({ activeKey }) => {

  const navigate = useNavigate();
  const { init, permissions: $p } = useAccount();
  const [key, setKey] = useState('1');

  useEffect(() => {
    if (init && activeKey) {
      setKey(activeKey);
    }
  }, [init, activeKey])

  const onTabClick = (key: string) => {
    switch (key) {
      case '1':
        navigate('/');
        break;
      case '2':
        navigate('/advanced/platform-game-data');
        break;
      case '3':
        navigate('/advanced/platform-provider-sort');
        break;
      case '4':
        navigate('/advanced/platform-game-list');
        break;
      case '5':
        navigate('/advanced/platform-game-rebate');
        break;
      case '6':
        navigate('/advanced/platform-license');
        break;
    }
  }

  const items: any = [
    { key: '1', label: <div className='text-center'>{i18n.t('platformData')}</div> },
    $p('gameData.getProviderListByCC') &&
    { key: '2', label: <div className='text-center'>{i18n.t('gameData')}</div> },
    $p('providerSort.getProviderSortList') &&
    { key: '3', label: <div className='text-center'>{i18n.t('gameProviderSorting')}</div> },
    $p('gameList.getGameList') &&
    { key: '4', label: <div className='text-center'>{i18n.t('gameList')}</div> },
    $p('providerCommission.getProviderCommissions') &&
    { key: '5', label: <div className='text-center'>{i18n.t('gameProviderCashbackSettings')}</div> },
    $p('license.getLicenses') &&
    { key: '6', label: <div className='text-center'>{i18n.t('uploadLicense')}</div> },
  ];
  return <Tabs activeKey={activeKey} items={items} onTabClick={onTabClick} />
};

// 上傳圖片
export const UploadImage = ({
  form, name,
  imageData, setImageData,
  url, disabled,
  preUrl,
  crop, cropShape = 'rect',
  maxCount = 1, w, h,
  onRemove, button,
  accept = ".jpg, .png",
  fillColor,
  remove = false,
  method = 'POST',
  submitLoading = () => {}
}: any) => {

  const [loading, setLoading] = useState(false);

  const handleFileListChange = async (data: UploadChangeParam<UploadFile<any>>) => {
    const { fileList } = data;
    setImageData(fileList);
  };

  useEffect(() => {
    if (imageData && (imageData[0] || imageData[1]) && imageData[0].status === 'done') {
      // submitLoading(true);

      const newFormData = new FormData();
      const file = imageData[0].originFileObj;

      // NOTE: 目前都只能傳一張
      determineImageAttributes(file, w, h, {
        pass: () => {
          newFormData.append('Media', file as RcFile);

          try {
            fetch(`api${url}`, {
              headers: {
                "Authorization": Cookies.get(COOKIE.TOKEN) as any
              },
              method,
              body: newFormData,
            })
              .then(res => {
                const response: any = res.json();
                if (res.ok) return response;
                else throw response;
              })
              .then(data => {
                // 成功
                if (data.State === 'Success') {
                  setImageData([{
                    uid: `${data.Data[0]}?${Date.now()}`,
                    name: `${data.Data[0]}?${Date.now()}`,
                    url: `${data.Data[0]}?${Date.now()}`,
                  }]);

                  if (form && name) {
                    form.setFieldValue(name, url);
                    form.validateFields([name]);
                  }
                } else {
                  failCase(data.Message);
                };
              })
          } catch (e: any) {
            failCase(e.Message);
          }
        },
        fail: () => {
          failCase('imageSizeWrong');
        }
      });
    }
  }, [imageData])

  // 上傳失敗
  const failCase = (m: any) => {
    const msg = RESPONSE_CODE[m as keyof typeof RESPONSE_CODE];
    message.error(msg || i18n.t('processingFailed'));
    // 一進來初始圖片先存useState 就不用外部設定
    preUrl ? setImageData([{
      uid: `${preUrl}?${Date.now()}`,
      name: `${preUrl}?${Date.now()}`,
      url: `${preUrl}?${Date.now()}`,
    }]) : setImageData([]);

    // setLoading(false);
    // submitLoading(false);
  }

  // 有上傳圖片才會顯示圖片,建站的時候還沒上傳圖片則不顯示圖片
  const isShowPicture = imageData?.length > 0 && imageData[0]?.url?.includes('https');

  const upload =
    <Upload
      multiple
      accept={accept}
      listType="picture"
      maxCount={maxCount}
      fileList={imageData}
      onChange={handleFileListChange}
      customRequest={(e: any) => e.onSuccess()}
      showUploadList={isShowPicture? {
        showRemoveIcon: remove
      }: false}
      onRemove={onRemove}
    >
      {button ? button : <Button type="primary" disabled={disabled}>{i18n.t('upload')}</Button>}
    </Upload>

  return (
    <Spin spinning={loading}>
      {
        crop
          ?
          <ImgCrop
            cropShape={cropShape}
            fillColor={fillColor}
            aspect={w ? w / h : 1}
            showGrid
            showReset
            resetText={`${i18n.t('reset')}`}
            modalTitle={`${i18n.t('editImage')}`}
          >
            {upload}
          </ImgCrop>
          : (upload)
      }
    </Spin>
  )
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Memo;
  index: number;
  children: React.ReactNode;
}
export const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input maxLength={100} showCount />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/* 1.9 統一輸入框格式 */
export const LayoutTextArea = ({
  formName,
  formLabel,
  formRules,
  autoSize = { minRows: 2, maxRows: 1 },
  maxLength = 100,
  disabled = false
}: {
  formName?: string;
  formLabel?: DefaultTFuncReturn;
  formRules?: any[];
  required?: boolean;
  autoSize?: { minRows: number, maxRows: number };
  maxLength?: number;
  disabled?: boolean;
}) => {
  return (
    <Form.Item name={formName} label={formLabel} rules={formRules}>
      <Input.TextArea
        size="middle"
        placeholder={`${i18n.t('inputData')}`}
        autoSize={autoSize}
        count={{
          show: true,
          max: maxLength,
        }}
        disabled={disabled}
      />
    </Form.Item>
  )
}

export const CopyButton: React.FC<{
  text: string;
}> = ({ text }) => {

  //copy text
  const handleCopyAccount = (text: string) => {
    navigator.clipboard.writeText(text);
    message.success(i18n.t('copySuccess'));
  };

  return (
    <Button onClick={() => handleCopyAccount(text)} className="center" size="small" type="link">
      <Image className="center" src={copyIcon} preview={false} />
    </Button>
  )
}